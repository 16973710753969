import React from 'react'
import Layout from './layout'
import Seo from './seo'
import SectionTitle from './sectionTitle'
import SectionDefault from './sectionDefault'
import { useInfoPagesData, pageLinks } from '../hooks/infoPages'

const LayoutInfo = ({ item: { title, description }, slug, proseClassName = '', children }) => {
  const pageTags = pageLinks(useInfoPagesData(), slug)

  return (
    <Layout currentInfoPageSlug={slug}>
      <Seo title={title} />
      <SectionTitle title={title} subtitle={description} tags={pageTags} style={`default`} />
      <SectionDefault>
        <div className={`prose mx-auto ${proseClassName}`}>{children}</div>
      </SectionDefault>
    </Layout>
  )
}

export default LayoutInfo
