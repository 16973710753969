import * as React from 'react'
import styles from '../styles/styles'
import axios from 'axios'

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      error: null,
      isSuccess: false,
      isLoading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrorReset = this.handleErrorReset.bind(this)
  }

  handleChange(event) {
    this.setState({
      email: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      isLoading: true,
    })

    axios
      .post('https://jbapp.vikdiesel.workers.dev/email', {
        email: this.state.email,
      })
      .then((r) => {
        this.setState({
          isLoading: false,
          isSuccess: true,
        })
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data.errorMessage
            ? err.response && err.response.data.errorMessage
            : err.message

        this.setState({
          isLoading: false,
          error: `Error: ${errorMessage}`,
        })
      })
  }

  handleErrorReset(event) {
    event.preventDefault()

    this.setState({
      error: null,
    })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.isSuccess && <div>Done! Thank you, you're in now)</div>}
        {this.state.error && (
          <div>
            {this.state.error}{' '}
            <span className="normal cursor-pointer" onClick={this.handleErrorReset}>
              Retry
            </span>
          </div>
        )}
        {!this.state.error && !this.state.isSuccess && (
          <div className="flex justify-center items-center max-w-3xl mx-auto">
            <input
              autoComplete="email"
              name="email"
              type="email"
              className={`${styles.input} flex-grow`}
              value={this.state.value}
              onChange={this.handleChange}
              required
            />
            <button
              type="submit"
              className="h-12 inline-flex items-center justify-center px-6 cursor-pointer"
            >
              OK
            </button>
          </div>
        )}
      </form>
    )
  }
}

export default SubscribeForm
