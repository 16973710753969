import React from 'react'
import { useInfoPagesData } from '../hooks/infoPages'
import LayoutInfo from '../components/layoutInfo'
import { Link } from 'gatsby'

const InfoItem = ({ title, slug, description }) => (
  <>
    <p>
      <Link to={`/info/${slug}/`}>{title}</Link>
    </p>
    <p>{description}</p>
  </>
)

const Info = () => {
  const pageItem = {
    title: 'Info',
    description: 'Questions & answers',
  }

  const infoPagesData = useInfoPagesData()

  return (
    <LayoutInfo item={pageItem} proseClassName="text-center">
      {infoPagesData.map(({ childMdx: page }) => (
        <InfoItem
          key={page.slug}
          slug={page.slug}
          title={page.frontmatter.title}
          description={page.frontmatter.description}
        />
      ))}
    </LayoutInfo>
  )
}

export default Info
